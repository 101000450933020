import Stack from '@mui/material/Stack';
import { useCallback, useContext, useEffect, useState } from 'react';
import UiContext from '../state/UiContext';
import { useUpdateLikelihood } from '../services/theories';
import SlideDownModal from './wrappers/SlideDownModal';
import Likelihood from './elements/Likelihood';
import Sugar from 'sugar';

const LikelihoodPanel = ({ theory, onSuccess, timeout }) => {
  const [currentLikelihood, setCurrentLikelihood] = useState();
  const [rankTimer, setRankTimer] = useState();
  const { toastSuccess, toastError } = useContext(UiContext);
  const updateLikelihood = useUpdateLikelihood();

  function getCurrentLikelihood() {
    return currentLikelihood !== undefined
      ? currentLikelihood
      : theory?.userLikelihood?.updatedAt &&
        Sugar.Date('50 days ago').isAfter(theory.userLikelihood.updatedAt).raw
      ? undefined
      : theory?.userLikelihood?.likelihood;
  }

  useEffect(
    () => () => {
      window.clearTimeout(rankTimer);
    },
    [rankTimer],
  );

  const onChangeLikelihood = useCallback(
    lh => () => {
      setCurrentLikelihood(lh);
      window.clearTimeout(rankTimer);
      setRankTimer(
        window.setTimeout(() => {
          updateLikelihood.mutate(
            { theorySlug: theory.slug, likelihood: lh },
            {
              onSuccess: () => {
                toastSuccess(`Answer recorded`);
                onSuccess && onSuccess();
              },
              onError: res => {
                setCurrentLikelihood(undefined);
                toastError(res.message);
              },
            },
          );
        }, timeout || 1000),
      );
    },
    [rankTimer, theory, onSuccess, toastError, updateLikelihood, timeout, toastSuccess],
  );

  return (
    <div className='flex flex-row justify-around w-full z-20 relative'>
      {[10, 30, 50, 70, 90].map(lh => (
        <Likelihood
          lh={lh}
          onClick={onChangeLikelihood(lh)}
          selected={getCurrentLikelihood() === lh}
          key={lh}
        />
      ))}
    </div>
  );
};

export default LikelihoodPanel;

export const LikelihoodModal = ({ open, prompt, theory, onSuccess, onClose }) => {
  return (
    <SlideDownModal open={open} title={prompt} onClose={onClose}>
      <Stack sx={{ px: 6, py: 5 }} spacing={3}>
        <LikelihoodPanel theory={theory} onSuccess={onSuccess} />
        <p className='text-xs'>
          Please make your best estimate given what you know now. You can change this later. We use
          this to better understand each other's stance on the question and track how strong the
          evidence is by awarding Sways to authors of influential evidence.
        </p>
      </Stack>
    </SlideDownModal>
  );
};
