import background from '../assets/images/home.png';
import Heading from '../components/Banners/Heading';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTheories } from '../services/theories';
import { useInfiniteScroll } from '../util/dom';
import DefaultTheoryImage from '../assets/images/Theory/theory.png';
import LikelihoodPanel from '../components/LikelihoodPanel';
import SignInModal from '../components/SignIn/SignInModal';
import AuthContext from '../state/AuthContext';
import { flattenPages } from '../util/query';
import { useNavigate } from 'react-router-dom';
import { useIsAbTest } from '../services/ab-tests';

const AllAtOnce = ({ theoriesQuery, theories, onSubmit, me, submitted }) => {
  useInfiniteScroll(theoriesQuery);

  return (
    <div className='mx-4 lg:mx-8 my-12 flex flex-col'>
      <p className='mx-8 lg:mx-12 text-base'>
        This quiz will measure your tendency to either agree or disagree with the beliefs of the
        majority. Please answer at least 5 questions. If you don't have a strong opinion on a
        question, you can skip it.
      </p>

      {theories?.map(theory => (
        <div className='mt-12 mx-6 md:mx-10' key={theory.id}>
          <div className='flex items-center'>
            <img
              className='object-cover rounded h-10 w-10'
              src={theory.image?.thumbnail?.url || DefaultTheoryImage}
              alt='Inquiry'
            />
            <p className='ml-2 lg:ml-4 text-xl font-bold text-regular'>{theory.title}</p>
          </div>
          <div className='max-w-md mt-4 ml-0 lg:ml-11'>
            <LikelihoodPanel theory={theory} />
          </div>
        </div>
      ))}

      {!theoriesQuery.isFetching && !theoriesQuery.hasNextPage && (
        <>
          <button
            onClick={onSubmit}
            className='mt-16 rounded text-white self-center p-3 bg-primary'
          >
            {submitted ? 'Processing...' : 'Submit'}
          </button>

          {me?.dissent === null && (
            <p className='font-bold text-highlighted mt-4 mx-8'>
              You need to answer more questions to get a dissent score
            </p>
          )}
        </>
      )}
    </div>
  );
};

const OnePerPage = ({ theoriesQuery, theories, onSubmit, me }) => {
  const [current, setCurrent] = useState(0);

  const next = useCallback(() => {
    setCurrent(current + 1);
    if (current + 2 >= theories.length) theoriesQuery.fetchNextPage();
  }, [current, setCurrent, theoriesQuery, theories]);

  useEffect(
    () =>
      current === theories.length &&
      !theoriesQuery.hasNextPage &&
      !theoriesQuery.isFetching &&
      onSubmit(),
    [current, theories, onSubmit, theoriesQuery],
  );

  if (theories.length === current) return <p className={'text-center mt-10'}>Processing...</p>;

  return (
    <div className='mx-4 lg:mx-8 my-12 flex flex-col'>
      <p className='mx-8 lg:mx-12 text-base'>
        This quiz will measure your tendency to either agree or disagree with the beliefs of the
        majority. Please answer at least 5 questions. If you don't have a strong opinion on a
        question, you can skip it.
      </p>

      <div className='mt-12 mx-6 md:mx-10'>
        <div className='flex items-center'>
          <img
            className='object-cover rounded h-10 w-10'
            src={theories[current]?.image?.thumbnail?.url || DefaultTheoryImage}
            alt='Inquiry'
          />
          <p className='ml-2 lg:ml-4 text-xl font-bold text-regular'>{theories[current]?.title}</p>
        </div>
        <div className='max-w-md min-w-sm mt-4 ml-0 lg:ml-11 flex flex-row'>
          <LikelihoodPanel theory={theories[current]} timeout={1} onSuccess={next} key={current} />
          <button className='ml-8' onClick={next}>
            skip
          </button>
        </div>
      </div>

      {!theoriesQuery.isFetching &&
        !theoriesQuery.hasNextPage &&
        current === theories.length &&
        me?.dissent == null && (
          <div>
            <p className='font-bold text-highlighted mt-4 mx-8'>
              You need to answer more questions to get a dissent score
            </p>
            <button onClick={() => setCurrent(0)}>reset quiz</button>
          </div>
        )}
    </div>
  );
};

const Quiz = () => {
  const theoriesQuery = useTheories({ status: 'published' });
  const theories = flattenPages(theoriesQuery.data?.pages);
  const [promptForEmail, setPromptForEmail] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { me, isSignedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const testOnePerPage = useIsAbTest('quiz-one-per');

  const onSubmit = useCallback(() => {
    if (!me || me.dissent === null) return;
    setSubmitted(true);
    if (!isSignedIn) setPromptForEmail(true);
  }, [setSubmitted, isSignedIn, setPromptForEmail, me]);

  useEffect(() => {
    if (isSignedIn && submitted) navigate('/quiz-result');
  }, [isSignedIn, submitted, navigate]);

  const Questions = testOnePerPage ? OnePerPage : AllAtOnce;

  return (
    <div>
      <Heading
        text='Dissent Quiz'
        tooltip='This quiz will ask you a series of empirical yes/no questions and figure out how much you dissent from consensus beliefs'
        backgroundImg={background}
      />

      <Questions
        theoriesQuery={theoriesQuery}
        theories={theories}
        onSubmit={onSubmit}
        submitted={submitted}
        me={me}
      />

      <SignInModal
        open={promptForEmail}
        title='Please enter your email to get your dissent score'
        onClose={() => {
          setPromptForEmail(false);
          setSubmitted(false);
        }}
        onSuccess={() => setPromptForEmail(false)}
        context='quiz'
      />
    </div>
  );
};

export default Quiz;
