import { Link as RouterLink } from 'react-router-dom';

const TheoryTitle = ({ theory, isLink, onClick }) => {
  const title = (
    <p
      id='theory-title'
      className='my-1 md:mb-3 text-xl md:text-2xl lg:text-3xl font-semibold md:text-center'
      onClick={() => !isLink && onClick()}
    >
      {theory?.title}
    </p>
  );
  return isLink ? (
    <RouterLink to={'/inquiry/' + theory?.slug} style={{ textDecoration: 'none' }}>
      {title}
    </RouterLink>
  ) : (
    title
  );
};

export default TheoryTitle;
