import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import ShareIcon from '@mui/icons-material/Share';
import BalanceIcon from '@mui/icons-material/Balance';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import RecommendIcon from '@mui/icons-material/Recommend';
import VisibilityIcon from '@mui/icons-material/Visibility';
import NotesIcon from '@mui/icons-material/Notes';
import { Skeleton } from '@mui/material';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import EditIcon from '@mui/icons-material/Edit';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import CompressIcon from '@mui/icons-material/Compress';
import ExpandIcon from '@mui/icons-material/Expand';
import AddIcon from '@mui/icons-material/Add';
import AttributionIcon from '@mui/icons-material/Attribution';
import ta from 'time-ago';
import Username from './elements/Username';
import classnames from 'classnames';

const Activity = ({ Icon, link, children, activity }) => {
  return (
    <RouterLink to={link || '#'}>
      <div
        className={classnames(
          'md:my-3 flex flex-row space-x-2 items-baseline text-sm md:text-base',
          { 'cursor-pointer': link },
        )}
      >
        <div className='p-3'>
          <Icon />
        </div>
        <div>
          {children}
          <p className='text-2xs mt-1 text-gray-400'>{ta.ago(activity.createdAt)}</p>
        </div>
      </div>
    </RouterLink>
  );
};

const Title = ({ children }) => <span className='font-bold text-regular'>{children}</span>;

const UserSignupActivity = ({ activity }) => {
  return (
    <Activity Icon={HowToRegIcon} activity={activity}>
      {activity.type === 'user-signup'
        ? 'signed up'
        : activity.type === 'add-author'
        ? 'added author to ' + activity.evidence?.title
        : 'invited someone'}
    </Activity>
  );
};

const UserTrustAdjust = ({ activity }) => {
  return (
    <Activity Icon={BalanceIcon} activity={activity}>
      Gave {activity.trustEarned} trust to @{activity.user.username}
    </Activity>
  );
};

const TheoryLikelihoodActivity = ({ activity }) => {
  const likelihood =
    activity.theoryLikelihoodLog?.likelihood || activity.theoryLikelihood?.likelihood;
  const answer =
    likelihood === 10
      ? 'No'
      : likelihood === 30
      ? 'Unlikely'
      : likelihood === 50
      ? 'Maybe'
      : likelihood === 70
      ? 'Likely'
      : likelihood === 90
      ? 'Yes'
      : likelihood + '%';
  return (
    <Activity Icon={BalanceIcon} link={'/inquiry/' + activity.theory?.slug} activity={activity}>
      <Title>{answer}</Title>
      {' on '}
      <Title>{activity.theory?.title}</Title>
    </Activity>
  );
};

const TheoryRankActivity = ({ activity }) => (
  <Activity Icon={RecommendIcon} link={'/inquiry/' + activity.theory.slug} activity={activity}>
    <Title>
      {activity.theoryRankLog.rank > 0 && '+'}
      {activity.theoryRankLog?.rank}
    </Title>
    {' on '}
    <Title>{activity.theory?.title}</Title>
  </Activity>
);

const TheoryActivity = ({ activity }) => (
  <Activity
    Icon={activity.type === 'theory-edit' ? EditIcon : LightbulbIcon}
    link={'/inquiry/' + activity.theory.slug}
    activity={activity}
  >
    {activity.type === 'theory-edit' ? 'Edited Inquiry ' : 'Created '}
    <Title>{activity.theory.title}</Title>
  </Activity>
);

const PublishedTheoryActivity = ({ activity }) => (
  <Activity
    Icon={activity.type === 'theory-unpublish' ? UnpublishedIcon : HistoryEduIcon}
    link={'/inquiry/' + activity.theory.slug}
    activity={activity}
  >
    <Title>{activity.theory.title}</Title>
    {
      {
        'theory-publish': ' was published',
        'theory-unpublish': ' was withdrawn',
        'theory-republish': ' was republished',
      }[activity.type]
    }
  </Activity>
);

const TheoryReadActivity = ({ activity }) => (
  <Activity Icon={VisibilityIcon} link={'/inquiry/' + activity.theory.slug} activity={activity}>
    {'Read '}
    <Title>{activity.theory.title}</Title>
  </Activity>
);

const EvidenceActivity = ({ activity }) => {
  const Icon = useMemo(() => {
    let _Icon = AppRegistrationIcon; // 'evidence-publish'
    switch (activity.type) {
      case 'evidence-edit':
        _Icon = EditIcon;
        break;
      case 'evidence-unpublish':
        _Icon = UnpublishedIcon;
        break;
      case 'cluster-add':
        _Icon = CompressIcon;
        break;
      case 'cluster-exclude':
        _Icon = ExpandIcon;
        break;
      case 'evidence-create':
      case 'evidence-draft':
      case 'evidence-activate':
        _Icon = AddIcon;
        break;
      case 'claim-authorship':
        _Icon = AttributionIcon;
        break;
      default:
        break;
    }

    return _Icon;
  }, [activity]);

  return (
    <Activity
      Icon={Icon}
      link={(activity.evidence.parent ? '/rebuttal/' : '/evidence/') + activity.evidence.slug}
      activity={activity}
    >
      {
        {
          'evidence-unpublish': 'Withdrew',
          'evidence-republish': 'Republished',
          'cluster-add': 'Marked as a duplicate:',
          'cluster-exclude': 'Marked as not a duplicate:',
          'claim-authorship': 'Claimed authorship of ',
          'evidence-activate': 'Posted ',
          'evidence-draft': 'Drafted ',
          'evidence-publish': 'Published ',
          'evidence-summarize': 'Summarized ',
          'evidence-edit': 'Edited ',
        }[activity.type]
      }
      <Title>{activity.evidence.title}</Title>
      {activity.evidence.for ? ' for ' : ' against '}
      <Title>{activity.theory?.title}</Title>
    </Activity>
  );
};

const EvidenceReadActivity = ({ activity }) => (
  <Activity
    Icon={VisibilityIcon}
    link={(activity.evidence.parent ? '/rebuttal/' : '/evidence/') + activity.evidence.slug}
    activity={activity}
  >
    {'Read '}
    <Title>{activity.evidence.title}</Title>
    {activity.evidence.for ? ' for ' : ' against '}
    <Title>{activity.theory?.title}</Title>
  </Activity>
);

const EvidenceRankActivity = ({ activity }) => (
  <Activity
    Icon={ThumbsUpDownIcon}
    activity={activity}
    link={(activity.evidence.parent ? '/rebuttal/' : '/evidence/') + activity.evidence?.slug}
  >
    <Title>
      {activity.evidenceRankLog.rank > 0 && '+'}
      {activity.evidenceRankLog.rank}
    </Title>
    {' on '}
    <Title>{activity.evidence.title}</Title>

    {activity.theory && (
      <>
        {' in '}
        <Title>{activity.theory.title}</Title>
      </>
    )}
  </Activity>
);

const commentLink = activity =>
  (activity.evidence ? (activity.evidence.parent ? '/rebuttal/' : '/evidence/') : '/inquiry/') +
  (activity.evidence || activity.theory)?.slug +
  '?showComment=' +
  activity.comment.id;

const commentBody = activity =>
  activity.comment?.body?.substring(0, 60) + (activity.comment?.body?.length > 60 ? '...' : '');

const commentTitle = activity => (activity.evidence || activity.theory)?.title;

export const CommentActivity = ({ activity }) => (
  <Activity Icon={NotesIcon} link={commentLink(activity)} activity={activity}>
    {'Commented '}
    <Title>{commentBody(activity)}</Title>
    {' on '}
    <Title>{commentTitle(activity)}</Title>
  </Activity>
);

export const CommentResponseActivity = ({ activity }) => (
  <Activity Icon={NotesIcon} link={commentLink(activity)} activity={activity}>
    {'Responded to '}
    <Title>{commentBody(activity)}</Title>
    {' on '}
    <Title>{commentTitle(activity)}</Title>
  </Activity>
);

export const CommentResolveActivity = ({ activity }) => (
  <Activity Icon={NotesIcon} activity={activity}>
    {'Resolved '}
    <Title>{commentBody(activity)}</Title>
    {' on '}
    <Title>{commentTitle(activity)}</Title>
  </Activity>
);

export const CommentReadActivity = ({ activity }) => (
  <Activity Icon={NotesIcon} activity={activity}>
    {'Read '}
    <Title>{commentBody(activity)}</Title>
    {' on '}
    <Title>{commentTitle(activity)}</Title>
  </Activity>
);

export const ShareActivity = ({ activity }) => (
  <Activity
    Icon={ShareIcon}
    activity={activity}
    link={
      activity.evidence
        ? '/evidence/' + activity.evidence.slug
        : activity.theory
        ? '/inquiry/' + activity.theory.slug
        : undefined
    }
  >
    {'Shared '}
    {activity.evidence && 'evidence: ' + activity.evidence?.title}
    {activity.theory && 'inquiry: ' + activity.theory?.title}
  </Activity>
);

export const actionComponents = {
  'add-author': UserSignupActivity,
  'claim-authorship': EvidenceActivity,
  'user-signup': UserSignupActivity,
  'user-invite': UserSignupActivity,
  'user-trust-adjust': UserTrustAdjust,
  'theory-activate': TheoryActivity,
  'theory-create': TheoryActivity,
  'theory-edit': TheoryActivity,
  'theory-read': TheoryReadActivity,
  'theory-likelihood-log': TheoryLikelihoodActivity,
  'theory-publish': PublishedTheoryActivity,
  'theory-republish': PublishedTheoryActivity,
  'theory-unpublish': PublishedTheoryActivity,
  'evidence-activate': EvidenceActivity,
  'evidence-draft': EvidenceActivity,
  'evidence-read': EvidenceReadActivity,
  'evidence-edit': EvidenceActivity,
  'evidence-summarize': EvidenceActivity,
  'evidence-publish': EvidenceActivity,
  'evidence-unpublish': EvidenceActivity,
  'evidence-republish': EvidenceActivity,
  'evidence-create': EvidenceActivity,
  'evidence-rank-log': EvidenceRankActivity,
  'cluster-add': EvidenceActivity,
  'cluster-exclude': EvidenceActivity,
  'comment-create': CommentActivity,
  'comment-respond': CommentResponseActivity,
  'comment-resolve': CommentResolveActivity,
  'comment-read': CommentReadActivity,
  'theory-rank-log': TheoryRankActivity,
  share: ShareActivity,
};

const ActivityList = ({ activities, showUsername, showTrust, skeleton }) =>
  activities?.length
    ? activities?.map((activity, i) => {
        const ActionComponent = actionComponents[activity.type];
        if (ActionComponent)
          return (
            <div key={i} className='flex justify-between items-baseline'>
              <ActionComponent activity={activity} />
              {showUsername && <Username username={activity.actingUser?.username} />}
              {showTrust && (
                <p className='ml-2 font-bold text-sm text-regular whitespace-nowrap'>
                  {(activity.trustEarned > 0 ? '+' : '') + activity.trustEarned + ' 📜'}
                </p>
              )}
            </div>
          );
        else return console.error('No component found for ', activity.type);
      })
    : skeleton
    ? [1, 2, 3, 4, 7, 8, 9, 10].map(i => <Skeleton height={85} key={i} />)
    : null;

export default ActivityList;
