import { useAdminTheories } from '../../services/theories';
import { flattenPages } from '../../util/query';
import { useInfiniteScroll } from '../../util/dom';

function AdminTheories() {
  const theoriesQuery = useAdminTheories({ status: 'published' });
  useInfiniteScroll(theoriesQuery);
  const theories = flattenPages(theoriesQuery.data?.pages);

  return (
    <div>
      {theories.map(t => (
        <div className='flex flex-row space-x-2' key={t.id}>
          <p>Subs: {t.emailSubscriptions}</p>
          <a href={'/inquiry/' + t.slug}>{t.title}</a>
        </div>
      ))}
    </div>
  );
}

export default AdminTheories;
