import Stat from './Stat';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import { FlameSquareIcon } from '../icons';
import SubjectOutlinedIcon from '@mui/icons-material/SubjectOutlined';
import React from 'react';

const EvidenceStats = ({ evidence }) => {
  return (
    <div className='text-xs md:text-xs xl:text-sm flex flex-row space-x-3 md:space-x-5 items-end'>
      <Stat
        title='Place: the sort order of this post as determined by user votes, sways, and age'
        Icon='#'
        number={evidence.place || '-'}
      />
      {evidence.swayCount !== undefined && (
        <Stat
          title='Sways: how many readers have changed their Answer after reading this'
          Icon={PsychologyOutlinedIcon}
          number={evidence.swayCount}
        />
      )}

      {evidence.rebuttalCount !== undefined && (
        <Stat
          title='Rebuttals: the number of posts arguing against this one'
          Icon={FlameSquareIcon}
          iconClassName={'mb-1'}
          number={evidence.rebuttalCount}
        />
      )}
      {/*{evidence.promotes !== undefined && (*/}
      {/*  <Stat title='Promotions' Icon={ThumbUpOutlinedIcon} number={evidence.promotes} />*/}
      {/*)}*/}
      {/*{evidence.demotes !== undefined && (*/}
      {/*  <Stat title='Demotes' Icon={ThumbDownOutlinedIcon} number={evidence.demotes} />*/}
      {/*)}*/}
      {evidence.commentCount !== undefined && (
        <Stat
          title='Comments: discussions about this post that are not Rebuttals'
          Icon={SubjectOutlinedIcon}
          number={evidence.commentCount}
        />
      )}
    </div>
  );
};

export default EvidenceStats;
