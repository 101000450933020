import TheoryCard from './TheoryCard';
import classnames from 'classnames';

const TheoryGrid = ({ className, theories, isFetching, rankable }) => (
  <div
    className={classnames(
      className,
      'grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-7 lg:gap-10 2xl:gap-14',
    )}
  >
    {theories?.map((theory, i) => (
      <TheoryCard
        key={i}
        theory={theory}
        user={theory?.user}
        date={theory?.createdAt}
        rankable={rankable}
      />
    ))}

    {isFetching && [1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map(k => <TheoryCard key={k} theory={null} />)}
  </div>
);

export default TheoryGrid;
