/**
 =========================================================
 * Material Kit 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import MuiLink from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import FooterImg from 'assets/images/footer.png';
import { makeStyles } from '@mui/styles';
import colors from 'assets/theme/base/colors';
import Subscribe from './Subscribe';
import { useIsAbTest } from '../../services/ab-tests';
import { Tooltip } from '@mui/material';
import Logo from 'assets/images/logo.png';

const useStyles = makeStyles({
  footerStyle: {
    backgroundImage: `url(${FooterImg})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'black',
    backgroundSize: 'cover',
  },
  link: {
    color: colors.white.main,
  },
  social: {
    color: colors.white.main,
    border: '1px solid white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 100,
    width: 40,
    height: 40,
  },
});

function CenteredFooter({ routes, socials }) {
  const classes = useStyles();
  const testHideSubscribe = useIsAbTest('subscribe-footer');

  const renderedRoutes = routes.map(link => (
    <Box
      key={link.name}
      component={RouterLink}
      to={link.href}
      style={{ textDecoration: 'none' }}
      variant='body2'
      className={classes.link}
      fontWeight='regular'
    >
      {link.name}
    </Box>
  ));

  const renderedSocials = socials.map(social => (
    <Tooltip title={social.tooltip} key={social.link}>
      <Box
        component={MuiLink}
        href={social.link}
        variant='body2'
        className={classes.social}
        fontWeight='regular'
      >
        {social.icon}
      </Box>
    </Tooltip>
  ));

  return (
    <Box component='footer' pb={6} className={classes.footerStyle}>
      {testHideSubscribe === false && <Subscribe />}

      <Grid container justifyContent='center' pt={6}>
        <Grid item xs={12}>
          <p className='text-white text-base space-x-2 font-bold flex items-center justify-center mb-10'>
            <img src={Logo} className='w-12 h-6' alt='Logo' /> <p>Evincer</p>
          </p>
        </Grid>
        <Grid item xs={10} lg={8}>
          <Stack
            direction='row'
            flexWrap='wrap'
            justifyContent='center'
            spacing={{ xs: 2, lg: 3, xl: 6 }}
            mb={3}
          >
            {renderedRoutes}
          </Stack>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Stack display='flex' direction='row' justifyContent='center' spacing={3} mt={1} mb={3}>
            {renderedSocials}
          </Stack>
        </Grid>
      </Grid>
      <Stack
        display='flex'
        direction='row'
        justifyContent='center'
        alignItems='center'
        spacing={3}
        mt={1}
        mb={3}
      ></Stack>
    </Box>
  );
}

// Setting default values for the props of CenteredFooter
// Typechecking props for the CenteredFooter
CenteredFooter.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  socials: PropTypes.arrayOf(PropTypes.object),
};

export default CenteredFooter;
