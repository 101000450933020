const ui = {
  commentLength: 3000,
  tagLength: 15,
  evidenceTitleLength: 60,
  citationLength: 1500,
  linkLength: 500,
  theoryTitleLength: 60,
  usernameLength: 12,
  displayNameLength: 20,
  bioLength: 256,
  evidenceFewChars: 800,
  evidenceManyChars: 1500,
  evidenceMaxChars: 2000,
};
export default ui;
