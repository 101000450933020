import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import ta from 'time-ago';
import classnames from 'classnames';

const TimeAgo = ({ date, className, children, onClick }) => (
  <div
    className={classnames('flex flex-row space-x-1 items-center', className, {
      'cursor-pointer': !!onClick,
    })}
    onClick={onClick ? onClick : undefined}
  >
    {children || <AccessTimeOutlinedIcon />}
    <p>{ta.ago(date)}</p>
  </div>
);

export default TimeAgo;
