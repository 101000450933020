import { fetchClient } from './api';
import useLocalStorageState from 'use-local-storage-state';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useJwt } from './auth';
import { useLocation, useSearchParams } from 'react-router-dom';
import UiContext from '../state/UiContext';

const apiClient = fetchClient();

export const track = async (type, data) => await apiClient.post('/analytics/event', { type, data });

export const useSetUserProperties = (once = false) => {
  const [localUserProperties, setLocalUserProperties] = useLocalStorageState(
    once ? 'userPropertiesOnce' : 'userProperties',
    { defaultValue: {} },
  );
  const [jwt] = useJwt();
  useEffect(() => {
    if (jwt)
      apiClient.post('/analytics/set?' + (once ? 'once=true' : ''), { data: localUserProperties });
  }, [jwt, localUserProperties, once]);

  return useCallback(
    properties => {
      if (
        Object.keys(properties).some(
          k =>
            properties[k] !== undefined &&
            (once
              ? !Object.keys(localUserProperties).includes(k)
              : localUserProperties[k] !== properties[k]),
        )
      ) {
        console.log('setting local user properties', { once });
        setLocalUserProperties(properties);
      }
    },
    [localUserProperties, setLocalUserProperties, once],
  );
};

export const useLandingEffect = () => {
  const [jwt] = useJwt();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();

  const landingProperties = useMemo(() => {
    const splt = pathname.split('/');
    const props = {
      landing: pathname,
      landing1: splt[1] || '[none]',
      landing2: splt[2] || '[none]',
      landing3: splt[3] || '[none]',
      landing4: splt[4] || '[none]',
    };

    props.shared = !!searchParams.get('sharedBy');
    props.sharedBy = searchParams.get('sharedBy') || '[none]';

    props.linkCode = searchParams.get('link') || '[none]';

    return props;
  }, [pathname, searchParams]);

  useEffect(() => {
    if (jwt) {
      track('land', landingProperties);
      searchParams.delete('link');
      searchParams.delete('sharedBy');
      setSearchParams(searchParams, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt]);
};

export const useUserProperties = () => {
  const { isXs, isSm, isMd, isLg, isXl, isXxl } = useContext(UiContext);
  const setUserProperties = useSetUserProperties(false);

  const { pathname } = useLocation();

  useEffect(() => {
    setUserProperties({
      isXs,
      isSm,
      isMd,
      isLg,
      isXl,
      isXxl,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
};
