import CommentEditor from './CommentEditor';
import Comment from './Comment';
import { flattenPages } from '../../util/query';
import { useGetComments } from '../../services/comments';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useIsAbTest } from '../../services/ab-tests';
import { useInfiniteScroll } from '../../util/dom';
import AuthContext from '../../state/AuthContext';
import { useSearchParams } from 'react-router-dom';
import UserAvatar from '../elements/UserAvatar';
import { Skeleton } from '@mui/material';

const CommentList = ({ subjectType, subject, setUnsaved, infiniteScroll }) => {
  const ref = useRef();
  const commentsQuery = useGetComments(
    { subjectType, subjectId: subject?.id },
    { enabled: !!subject },
  );
  const comments = flattenPages(commentsQuery.data?.pages);
  const [hasUnsavedComment, setHasUnsavedComment] = useState(false);
  const [unsavedResponses, setUnsavedResponses] = useState({});
  const testPreviewResponse = useIsAbTest('preview-response');
  const { me } = useContext(AuthContext);
  const [searchParams, setSearchParams] = useSearchParams();

  useInfiniteScroll(commentsQuery, { active: infiniteScroll });

  const setUnsavedResponse = useCallback(
    (id, u) => setUnsavedResponses(urs => ({ ...urs, id: u })),
    [setUnsavedResponses],
  );
  useEffect(
    () =>
      setUnsaved &&
      setUnsaved(hasUnsavedComment || Object.keys(unsavedResponses).some(k => unsavedResponses[k])),
    [hasUnsavedComment, unsavedResponses, setUnsaved],
  );

  useEffect(() => {
    if (searchParams.get('viewComments')) {
      searchParams.delete('viewComments');
      setSearchParams(searchParams, { replace: true });
      const interval = window.setInterval(() => {
        if (ref?.current) {
          ref.current.scrollIntoView({ behavior: 'smooth' });
          window.clearInterval(interval);
        }
      }, 100);
    }
  }, [searchParams, setSearchParams, ref]);

  return (
    <div className='space-y-2 > * mb-10 text-sm' ref={ref}>
      <div className='flex flex-row space-x-3 p-3'>
        <UserAvatar user={me} className='size-8 rounded-full' />

        <div className='w-full'>
          <CommentEditor
            subjectType={subjectType}
            subject={subject}
            setUnsavedComment={setHasUnsavedComment}
          />
        </div>
      </div>
      {comments.map(comment => (
        <Comment
          key={comment.id}
          subject={subject}
          comment={comment}
          setUnsavedResponse={setUnsavedResponse}
          previewResponse={testPreviewResponse}
        />
      ))}

      {((commentsQuery.isFetching && !commentsQuery.isRefetching) ||
        commentsQuery.isFetchingNextPage) &&
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map(k => <Skeleton key={k} height={180} />)}

      {commentsQuery.hasNextPage && !infiniteScroll && (
        <button
          className='text-xs mt-5 cursor-pointer text-primary mx-auto block'
          onClick={() => commentsQuery.fetchNextPage()}
        >
          load more
        </button>
      )}
    </div>
  );
};

export default CommentList;
