import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetUser } from '../services/users';
import AuthContext from '../state/AuthContext';
import SubscriptionButton from 'components/SubscriptionButton';
import { useIsTrusted } from '../services/config';
import CredentialModals from '../components/CredentialModals';
import BannerImg from 'assets/images/bannerImg2.png';
import { EditIcon } from '../components/icons';
import ImageUploadButton from '../components/ImageUploadButton';
import classnames from 'classnames';
import HeadTags from '../components/HeadTags';
import { avatar } from '../util/avatar';
import Occupation from '../components/elements/Occupation';
import UserEditor from './UserEditor';
import UserEvidence from './UserEvidence';
import UserTrust from './UserTrust';
import TrustLevel from '../components/elements/TrustLevel';
import UserActivity from './UserActivity';

const StatButton = ({ name, value, setStat, highlighted, disabled }) => {
  return (
    <button
      className={classnames(
        'flex flex-col items-center text-lg font-bold',
        disabled ? '' : 'cursor-pointer',
        highlighted === name ? 'text-primary' : 'text-regular',
      )}
      onClick={() => !disabled && setStat(name === highlighted ? '' : name)}
    >
      {value}
      <p
        className={classnames(
          'text-gray-400 text-sm',
          highlighted === name ? 'text-primary' : 'text-regular',
        )}
      >
        {name}
      </p>
    </button>
  );
};

const UserProfile = () => {
  const { me, isSignedIn, isNamed, isAdmin } = useContext(AuthContext);

  const params = useParams();
  const user = useGetUser({ idOrUsername: params.username }).data?.data;
  const [isEditing, setIsEditing] = useState(false);
  const [isTrustedToInitiateConversation] = useIsTrusted('initiateConversation');
  const [isTrustedToSubscribeToUser, trustLevelRequiredToSubscribeToUser] =
    useIsTrusted('subscribeToUser');
  const navigate = useNavigate();
  const [credentialsOpen, setCredentialsOpen] = useState(false);
  const [clampBio, setClampBio] = useState(true);
  const [stat, setStat] = useState('');

  const isMe = !params?.username || me?.username === params.username;

  const onClickUsername = () => isAdmin && navigate('/admin/user/' + (user.username || user.id));

  function onClickSubscriptionSettings() {
    navigate('/subscription-settings');
  }

  function onClickSendMessage() {
    if (isNamed && isSignedIn) navigate(`/messages/${user.username}`);
    else setCredentialsOpen(true);
  }

  return (
    <div className='flex flex-col md:flex-row'>
      <div className='relative md:max-w-xl md:w-1/2 shrink-0'>
        <HeadTags title={user?.username ? '@' + user?.username : 'User Profile'} />

        <div
          className='object-center bg-cover bg-center'
          style={{
            backgroundImage: `url(${user?.backgroundImage?.url || BannerImg})`,
            aspectRatio: '3/1',
          }}
        >
          {isMe && (
            <ImageUploadButton
              field='backgroundImage'
              show={isEditing}
              onSuccess={() => setIsEditing(false)}
            />
          )}
        </div>

        <div className='relative -top-6 bg-white rounded-3xl md:rounded-none border border-white'>
          <div className='absolute w-full -top-10 px-4 flex flex-row items-center text-sm '>
            <div
              className='rounded-3xl md:rounded w-20 h-20 relative -top-3'
              style={{
                backgroundImage: `url(${user?.profileImage?.url || avatar(user)})`,
                backgroundSize: 'cover',
                '&:hover': {
                  boxShadow: isMe ? 'inset 0 0 0 2000px rgba(0, 6, 29, 0.75)' : '',
                },
              }}
            >
              {isMe && (
                <ImageUploadButton
                  field='profileImage'
                  show={isEditing}
                  onSuccess={() => setIsEditing(false)}
                />
              )}
            </div>

            {isMe && (
              <button
                onClick={() => setIsEditing(!isEditing)}
                className='border border-primary p-2 ml-4 bg-white rounded text-primary'
              >
                <img src={EditIcon} className='w-[14px] h-[14px]' alt='Edit' />
              </button>
            )}

            {isMe ? (
              <button
                onClick={onClickSubscriptionSettings}
                className='ml-auto px-3 py-2 rounded text-white bg-primary'
              >
                Subscription Settings
              </button>
            ) : isTrustedToInitiateConversation ? (
              <button
                onClick={onClickSendMessage}
                className='ml-auto px-3 py-2 rounded text-white bg-primary'
              >
                Send Message
              </button>
            ) : null}
          </div>

          {isEditing ? (
            <UserEditor onClose={() => setIsEditing(false)} />
          ) : (
            <>
              <div className='mt-16 mx-6 text-xl flex flex-row items-baseline space-x-4'>
                <p onClick={onClickUsername} className='font-bold text-regular'>
                  @{user?.username || (isAdmin && user?.id)}
                </p>
                <div className='space-y-1'>
                  <p className='text-unselected font-thin'>{user?.displayName}</p>
                  <Occupation user={user} noTooltip className='text-sm text-gray-400' />
                  {user?.membership && <p className='text-sm italic'>{user?.membership}</p>}
                </div>
              </div>
              <p
                className={classnames(
                  'mt-8 mx-6 text-sm text-unselected',
                  clampBio ? 'line-clamp-2 cursor-pointer' : '',
                )}
                onClick={() => setClampBio(false)}
              >
                {user?.bio}
              </p>
            </>
          )}
          <div className='px-6 mt-4 w-full grid gap-2 grid-cols-auto-fit-7 sm:grid-cols-auto-fit-9'>
            {/*<!-- grid-cols-6 sm:grid-cols-10 md:grid-cols-8 lg:grid-cols-12'>-->*/}
            {user?.badgeTheories
              ?.filter(t => t.image?.thumbnail?.url)
              .map(t => (
                <img
                  key={t.slug}
                  className='cursor-pointer size-7 sm:size-8 rounded'
                  src={t.image.thumbnail.url}
                  alt={t.slug + ' badge'}
                  onClick={() => navigate('/inquiry/' + t.slug)}
                />
              ))}
          </div>
          <div className='flex flex-row justify-around mt-6 md:mt-14'>
            <StatButton
              name='Evidence'
              value={user?.evidenceCount}
              highlighted={stat}
              setStat={setStat}
            />
            <StatButton name='Sways' value={user?.swayCount} highlighted={stat} setStat={setStat} />
            <StatButton
              name='Level'
              value={user?.trustLevel}
              setStat={setStat}
              disabled={!isMe}
              highlighted={stat}
            />
          </div>
          {stat === 'Level' && (
            <div className='p-6'>
              <TrustLevel user={me} />
            </div>
          )}
        </div>
      </div>

      {['Evidence', 'Sways'].includes(stat) ? (
        <UserEvidence user={user} sways={stat === 'Sways'} />
      ) : stat === 'Level' ? (
        <UserTrust me={me} />
      ) : (
        <UserActivity user={user || me} />
      )}

      {!isMe && user && (
        <SubscriptionButton
          subscription={user.subscription}
          dataId={user.id}
          dataKey='user'
          path='/user-subscription'
          disabled={!isTrustedToSubscribeToUser}
          tooltip={
            isTrustedToSubscribeToUser
              ? 'Get notified when @' + user.username + ' posts new content'
              : 'Subscribing to a user requires Level ' + trustLevelRequiredToSubscribeToUser
          }
        />
      )}
      <CredentialModals
        open={credentialsOpen}
        requireUsername={true}
        signinPrompt={'Please enter your email before sending a message'}
        usernamePrompt={'Please choose a username before sending a message'}
        onSuccess={onClickSendMessage}
        onClose={() => setCredentialsOpen(false)}
        context='messaging user'
      />
    </div>
  );
};

export default UserProfile;
