import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useEvidence, useGetRevisions } from '../services/evidence';
import EvidenceNode from '../components/Evidence/EvidenceNode';
import Stack from '@mui/material/Stack';
import { Link as RouterLink } from 'react-router-dom';
import SubscriptionButton from 'components/SubscriptionButton';
import colors from '../assets/theme/base/colors';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import HeadTags from '../components/HeadTags';
import RevisionHistory from '../components/RevisionHistory';
import EvidenceEditor from '../components/Evidence/EvidenceEditor';
import { useIsTrusted } from '../services/config';
import { sameId } from '../util/id';
import AuthContext from '../state/AuthContext';
import { NoIcon, YesIcon } from '../components/Evidence/YesNoIcon';
import DefaultTheoryImage from '../assets/images/Theory/theory.png';
import classnames from 'classnames';
import { useGetTheory } from '../services/theories';
import { useClusteredEvidence, useClusters, useIsDuplicateCb } from '../services/clusters';
import CommentList from '../components/Comments/CommentList';
import Tutorial from '../components/Tutorial';
import { useIsAbTest } from '../services/ab-tests';
import { FlameSquareIcon } from '../components/icons';

const Evidence = () => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const evidence = useEvidence(params.slug).data?.data;
  const theory = useGetTheory(evidence?.theory?.slug).data?.data;
  const clusterQuery = useClusters(theory?.id);
  const [showDuplicates, setShowDuplicates] = useState(false);
  const [showRevisionHistory, setShowRevisionHistory] = useState(false);
  const [restoringEvidence, setRestoringEvidence] = useState(null);
  const [isTrustedToEdit] = useIsTrusted('editEvidence');
  const { me, isAdmin } = useContext(AuthContext);
  const { pathname } = useLocation();
  const testAuthorTutorial = useIsAbTest('author-tutorial', searchParams.get('author'));
  const isDuplicate = useIsDuplicateCb(theory?.id);

  const authorTutorialSteps = [
    {
      anchorId: 'evidence-title-' + evidence?.slug,
      text: `@${evidence?.user?.username} posted your work as Evidence to our ongoing Inquiry, "${theory?.title}"`,
      placement: 'bottom',
    },
    {
      anchorId: 'rank-panel',
      text: `User votes have ranked it as the #${evidence?.place} strongest Evidence in the "${
        evidence?.for ? 'Yes' : 'No'
      }" column.`,
      placement: 'top-start',
    },
    {
      anchorId: 'claim-button',
      text: 'If you would like to address questions or facilitate other communication with researchers here, please claim authorship using the button below.',
      placement: 'top-end',
      nextId: 'claim-button',
    },
  ];

  useEffect(() => {
    setShowRevisionHistory(false);
  }, [pathname]);

  useEffect(() => {
    if (searchParams.get('showDuplicates')) {
      setShowDuplicates(true);
      searchParams.delete('showDuplicates');
      setSearchParams(searchParams, { replace: true });
    }
  }, [searchParams, setSearchParams, setShowDuplicates]);

  useEffect(() => {
    if (searchParams.get('showRevisionHistory')) setShowRevisionHistory(true);
  }, [searchParams, setSearchParams, setShowRevisionHistory]);

  const cluster = useMemo(
    () =>
      clusterQuery.data?.data.find(
        c => c?.members?.length > 1 && evidence && c.members.includes(evidence.id),
      ),
    [clusterQuery, evidence],
  );

  const hiddenDuplicates = useClusteredEvidence(
    !!evidence && showDuplicates && cluster?.id,
  ).data?.data?.filter(e => e.id !== evidence.id);

  if (!evidence) return <p>Loading...</p>;

  let ancestry = [];
  evidence?.parents
    .map((parent, i) => ({ ...parent, for: i % 2 ? evidence.for : !evidence.for }))
    .slice()
    .reverse()
    .forEach((parent, i) =>
      ancestry.push({
        link: (i === 0 ? '/evidence/' : '/rebuttal/') + parent.slug,
        text: parent.title,
        for: parent.for,
        icon: parent.for ? <YesIcon /> : <NoIcon />,
        color: colors.evidence[parent.for ? 'for' : 'against'],
      }),
    );

  ancestry.push({
    text: evidence.title,
    for: evidence.for,
    icon: evidence.for ? <YesIcon /> : <NoIcon />,
    color: colors.evidence[evidence.for ? 'for' : 'against'],
  });

  const renderAncestry = (ancestry, level = 0) =>
    !!ancestry.length && (
      <div
        className={classnames('p-2 border rounded mt-3', { 'ml-1': level > 0 })}
        style={{ backgroundColor: ancestry[0].color }}
      >
        <RouterLink to={ancestry[0].link || '#'}>
          <div className='flex items-center text-[10px] text-unselected'>
            {!!level ? <FlameSquareIcon className='h-3 inline ml-1' /> : ancestry[0].icon}
            <p className={'ml-1 text-xs'}>{ancestry[0].text}</p>
          </div>
        </RouterLink>
        {renderAncestry(ancestry.slice(1), level + 1)}
      </div>
    );

  return (
    <Stack spacing={2}>
      <HeadTags
        title={evidence.title}
        description={evidence.body}
        image={evidence.theory.image?.url}
      />

      <div className='p-4'>
        <RouterLink to={'/inquiry/' + evidence.theory.slug}>
          <div className='flex items-center'>
            <img
              className='object-cover rounded h-10 w-10'
              src={evidence.theory?.image?.thumbnail?.url || DefaultTheoryImage}
              alt='Inquiry'
            />
            <p className='ml-2 lg:ml-4 text-sm lg:text-lg font-bold text-unselected'>
              {evidence.theory.title}
            </p>
          </div>
        </RouterLink>
        {evidence.parent && renderAncestry(ancestry)}
      </div>

      {restoringEvidence ? (
        <EvidenceEditor
          copyEvidence={restoringEvidence}
          isFor={evidence.for}
          updatingEvidence={evidence}
          parent={evidence.parents.length ? evidence.parents[0] : undefined}
          theory={theory || evidence.theory}
          onSuccess={() => setRestoringEvidence(null)}
          onCancel={() => setRestoringEvidence(null)}
        />
      ) : (
        <div className='px-4'>
          <EvidenceNode
            startExpanded={true}
            theory={theory || evidence.theory}
            evidence={evidence}
            level={1}
            parentEvidence={evidence.parents.length ? evidence.parents[0] : undefined}
            hasHiddenDuplicates={!showDuplicates && isDuplicate(evidence.id)}
            onClickShowDuplicates={() => setShowDuplicates(true)}
          />
          {!!cluster &&
            hiddenDuplicates?.map(e => (
              <EvidenceNode
                key={e.id}
                theory={evidence.theory}
                evidence={e}
                level={1}
                parentEvidence={e.parent}
              />
            ))}
        </div>
      )}

      {showRevisionHistory && !restoringEvidence && (
        <div className='px-5 py-12'>
          <RevisionHistory
            subject={evidence}
            useQuery={useGetRevisions}
            onRestore={
              (isAdmin || isTrustedToEdit || sameId(me, evidence.user)) &&
              (revision => setRestoringEvidence(revision))
            }
          />
        </div>
      )}

      <CommentList subject={evidence} subjectType='evidence' infiniteScroll={true} />

      <SubscriptionButton
        subscription={evidence.subscription}
        dataId={evidence.id}
        dataKey='evidence'
        path='/evidence-subscription'
        tooltip='Get notified about new rebuttals and comments'
      />

      {testAuthorTutorial && <Tutorial name='author-landing' steps={authorTutorialSteps} />}
    </Stack>
  );
};

export default Evidence;
