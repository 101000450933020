import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import BannerBackground from '../assets/images/home.png';
import Heading from '../components/Banners/Heading';
import { useTheories } from '../services/theories';
import { useInfiniteScroll } from '../util/dom';
import {
  getDefinition,
  getProfile,
  getRoleName,
  getTeamName,
  getDissentThresholds,
  roles,
  teams,
  getCertaintyThresholds,
} from '../util/concurrency';
import { reportShare, useMe } from '../services/users';
import HeadTags from '../components/HeadTags';
import { useContext, useState } from 'react';
import AuthContext from '../state/AuthContext';
import UsernameModal from '../components/UsernameModal';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import UiContext from '../state/UiContext';
import { useIsAbTest } from '../services/ab-tests';
import { flattenPages } from '../util/query';
import TheoryGrid from '../components/Cards/TheoryGrid';

const QuizResult = () => {
  const theoriesQuery = useTheories({});
  useInfiniteScroll(theoriesQuery);
  const theories = flattenPages(theoriesQuery.data?.pages);
  const meQuery = useMe();
  const me = meQuery.data?.data;
  const { isSignedIn } = useContext(AuthContext);
  const { toastSuccess } = useContext(UiContext);
  const team = getTeamName(me);
  const role = getRoleName(me);
  const [profile, advice, roleExplanation] = getProfile(team, role);
  const [chooseUsername, setChooseUsername] = useState(false);
  const navigate = useNavigate();
  const testInquiries = useIsAbTest('inquiries-after-quiz');

  if (meQuery.isLoading) return <p>Loading...</p>;
  else if (!me || !isSignedIn)
    return (
      <p className={'p-8 text-center font-bold'}>
        Please{' '}
        <span className='text-primary cursor-pointer' onClick={() => navigate('/sign-up')}>
          Sign Up
        </span>{' '}
        to view dissent quiz results
      </p>
    );

  const link = `${window.location.origin}/quiz`;

  const Graph = ({ options, selected, value, thresholds }) => {
    const max = thresholds[0] + thresholds[1];
    const percent = v => Math.round(Math.max(0, v / max) * 100);
    return (
      <div className='relative'>
        <div className='grid grid-cols-3 overflow-visible border-b-2 border-dashed'>
          {options.map(o => (
            <p
              className={classnames(
                'text-center text-lg md:text-2xl p-3',
                o === selected ? 'text-regular font-bold' : 'text-unselected',
              )}
              key={o}
            >
              {o}
            </p>
          ))}
          {thresholds.map(t => (
            <div className='absolute bottom-0 text-unselected' style={{ left: percent(t) + '%' }}>
              <p className='relative -left-0.5'>|</p>
            </div>
          ))}
        </div>
        <div className={'absolute flex'} style={{ left: percent(value) + '%' }}>
          <ArrowUpwardIcon sx={{ position: 'absolute', left: '-8px' }} />
        </div>
      </div>
    );
  };

  return (
    <div>
      <HeadTags>
        <script async src='https://platform.twitter.com/widgets.js' charSet='utf-8'></script>
      </HeadTags>

      <Heading text='Quiz Results' backgroundImg={BannerBackground} />

      <div className='mx-4 lg:mx-8 mt-8 lg:mt-12 mb-10 flex flex-col items-center'>
        <div className='max-w-lg mx-6 text-base mt-6 mb-2'>
          <div className='flex flex-col space-y-6'>
            <Graph
              options={[teams.agree, teams.neither, teams.disagree]}
              selected={team}
              value={me?.dissent}
              thresholds={getDissentThresholds()}
            />
            <Graph
              options={[roles.uncertain, roles.neither, roles.certain]}
              selected={role}
              value={me?.certainty}
              thresholds={getCertaintyThresholds()}
            />
          </div>
          <p className='font-bold mt-10'>
            {team}: <span className='font-normal'>{getDefinition(team)}</span>
          </p>
          <p className='font-bold mt-2'>
            {role}: <span className='font-normal'>{getDefinition(role)}</span>
          </p>
          <p className='text-unselected italic mt-6'>{profile}</p>
          <p className='text-regular mt-4'>{advice}</p>
          <p className='text-unselected italic mt-4'>{roleExplanation}</p>
        </div>

        <div className='border rounded mt-10 p-3 lg:p-5'>
          <p className='text-base'>
            You can <span className='font-bold'>share this quiz</span> with your friends to compare
            dissent and certainty.
          </p>
          <div className='border-2 p-4 border-slate-400 rounded flex flex-row space-x-4 justify-center items-center mt-2'>
            <p className='text-xs ls:text-sm'>Link: {link}</p>
            <button
              className='px-2 pb-1 rounded border-2'
              onClick={() =>
                navigator?.clipboard?.writeText(link).then(() => {
                  toastSuccess('Copied to Clipboard');
                  reportShare({ link });
                })
              }
            >
              <ContentCopyIcon />
            </button>
          </div>
        </div>

        {testInquiries && (
          <p className='text-center font-bold text-2xl mt-16 lg:mt-32'>
            Challenge your beliefs by exploring our Inquiries
          </p>
        )}
        {testInquiries && (
          <p className='text-center italic mt-2 mb-10'>The top evidence on both sides</p>
        )}
        {testInquiries && <TheoryGrid theories={theories} isFetching={theoriesQuery.isFetching} />}

        <UsernameModal open={chooseUsername} onClose={() => setChooseUsername(false)} />
      </div>
    </div>
  );
};

export default QuizResult;
