import Navbar from 'components/Navbar/Navbar';
import Footer from 'components/Footer/Footer';
import { Routes, useLocation } from 'react-router-dom';
import SignInModal from './SignIn/SignInModal';
import Toast from './Toast';
import React, { useContext, useEffect, useState } from 'react';
import UiContext from '../state/UiContext';
import { useUpdateUserQueryData } from '../services/users';
import { getRoutes } from '../state/menu-and-routes';
import { useClearCache } from 'react-clear-cache';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import AuthContext from '../state/AuthContext';
import { useUserProperties } from '../services/analytics';
import Reddit from 'util/reddit';
import PushNotificationContext from '../contexts/PushNotificationContext';

function Layout() {
  const theme = useTheme();
  const { toastInfo } = useContext(UiContext);
  const { me, signingIn, setSigningIn } = useContext(AuthContext);
  const { pathname } = useLocation();
  const updateUserQueryData = useUpdateUserQueryData();
  const { subscribe, unsubscribe } = useContext(PushNotificationContext);
  const {
    loading: reloading,
    isLatestVersion,
    emptyCacheStorage,
  } = useClearCache({ duration: 5 * 60 * 1000 });
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [selfChannel, setSelfChannel] = useState();
  useUserProperties();

  useEffect(() => {
    if (me && !me.completedSignup) {
      Reddit.init(me.id);
      Reddit.track('PageView');
    }
  }, [me]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const hideFooter = [
    /^\/$/,
    /^\/comments\/?$/,
    /^\/evidence\/?$/,
    /^\/inquiries\/new\/?$/,
    ///^\/inquiry\/.+\/?$/,
  ].some(regex => pathname.match(regex));

  useEffect(() => {
    if (!isLatestVersion && !showUpdateButton) setShowUpdateButton(true);
  }, [isLatestVersion, showUpdateButton, setShowUpdateButton]);

  useEffect(() => {
    if (!me?.id) return;

    const name = `private-self-${me?.id}`;
    const channel = subscribe(name);
    setSelfChannel(channel);
    return () => unsubscribe(name);
  }, [me?.id, subscribe, unsubscribe, setSelfChannel]);

  useEffect(() => {
    if (!selfChannel) return;

    selfChannel.bind('update-dms', ({ dms }) => updateUserQueryData({ dms }));

    selfChannel.bind('trust-earned', ({ delta, trustEarned, trustLevel }) => {
      const newLevel = trustLevel > me.trustLevel;
      updateUserQueryData({ trustEarned, trustLevel });
      const tooltip =
        'Your activity earns you 📜 which is applied towards attaining Levels, granting additional abilities';
      toastInfo(delta + ' 📜', tooltip);
      if (newLevel) toastInfo('You reached Level ' + trustLevel, tooltip);
    });

    return () => {
      selfChannel.unbind('update-dms');
      selfChannel.unbind('trust-earned');
    };
  }, [me, selfChannel, updateUserQueryData, toastInfo]);

  return (
    <>
      <Box
        position='relative'
        py={0.25}
        sx={{
          backgroundColor: theme.palette.white.main,
          borderBottom: '1px solid rgba(0,0,0,0.05)',
        }}
      >
        <Navbar selfChannel={selfChannel} transparent relative light center />
      </Box>

      {showUpdateButton && (
        <Container sx={{ p: 1 }}>
          <div className='flex items-center'>
            <div>
              <p className='font-bold text-sm'>A new version of Evincer is available!</p>
              <p className='text-orange-700 text-xs'>
                Updating will cause you to lose any unsaved changes.
              </p>
            </div>
            <button
              className='border rounded p-2 text-primary ml-5'
              disabled={reloading}
              onClick={e => {
                e.preventDefault();
                emptyCacheStorage();
              }}
            >
              Update
            </button>
          </div>
        </Container>
      )}

      <div className='w-full mx-auto min-h-screen'>
        <Box
          width='100%'
          p={0}
          justifyContent='center'
          height='100%'
          maxWidth={1440}
          margin={'auto'}
        >
          <Box>
            <Routes>{getRoutes()}</Routes>

            <SignInModal
              open={!!signingIn}
              title={signingIn}
              onClose={() => setSigningIn(false)}
              context='<Layout/>'
            />

            <Toast />
          </Box>
        </Box>
      </div>

      {!hideFooter && <Footer />}
    </>
  );
}

export default Layout;
