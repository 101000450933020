const id = assoc =>
  assoc?.id || (typeof assoc === 'string' && assoc) || (assoc === null ? null : undefined);

export default id;

export const sameId = (assocA, assocB) => {
  const idA = id(assocA);
  const idB = id(assocB);
  return idA === idB && idA !== undefined;
};
