import BodyEditor from 'components/BodyEditor';
import Container from '@mui/material/Container';
import Body from '../../components/Body';
import {
  useCreateNewsletter,
  useNewsletters,
  useSendMonthlyReview,
  useUpdateNewsletter,
} from '../../services/newsletters';
import UiContext from '../../state/UiContext';
import { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import { usePrompt } from '../../hooks/react-router-dom-extension';

const AdminNewsletter = () => {
  const newsletters = useNewsletters().data?.data;
  const createNewsletter = useCreateNewsletter();
  const updateNewsletter = useUpdateNewsletter();
  const { toastSuccess, toastError } = useContext(UiContext);
  const [body, setBody] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const unsent = newsletters?.some(n => !n.sent);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [next, setNext] = useState(false);
  const sendMonthlyReview = useSendMonthlyReview();
  const prompt = 'Discard unsaved changes?';
  usePrompt(prompt, unsavedChanges);

  function submit() {
    const options = {
      onSuccess: res => {
        toastSuccess(res.message);
        setIsUpdating(false);
        setBody('');
        setUnsavedChanges(false);
      },
      onError: res => toastError(res.message),
    };
    if (isUpdating) updateNewsletter.mutate({ body }, options);
    else createNewsletter.mutate({ body }, options);
  }

  function cancel() {
    if (unsavedChanges && !window.confirm(prompt)) return;

    setBody('');
    setIsUpdating(false);
    setUnsavedChanges(false);
  }

  function sendReview() {
    if (window.confirm('Send monthly review now?'))
      sendMonthlyReview.mutate(
        { next },
        {
          onSuccess: data => toastSuccess(data.message),
        },
      );
  }

  return (
    <Container>
      <div className='flex flex-row space-x-2'>
        <button className='text-secondary cursor-pointer' onClick={sendReview}>
          Send Review Now
        </button>
        <button onClick={() => setNext(!next)}>Next</button>
        <input type='checkbox' checked={next} onChange={() => setNext(!next)} />
      </div>

      {(!unsent || isUpdating) && (
        <Stack>
          <BodyEditor
            body={body}
            onChange={t => {
              setUnsavedChanges(true);
              setBody(t);
            }}
            autoFocus
          />
          <Stack direction='row'>
            <Button variant='outlined' onClick={submit}>
              Submit
            </Button>
            <Button variant='outlined' onClick={cancel}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      )}

      {newsletters?.map((n, i) => (
        <Card key={i} sx={{ m: 3 }}>
          <Body
            body={n.body}
            onClick={() => {
              setIsUpdating(true);
              setBody(n.body);
            }}
          />
          <Stack direction='row' spacing={3}>
            <Typography>Created</Typography>
            <Typography>{n.createdAt}</Typography>
            {n.sent && (
              <>
                <Typography>Sent</Typography>
                <Typography>{n.updatedAt}</Typography>
              </>
            )}
            {!n.sent && (
              <Button
                onClick={() => {
                  setIsUpdating(true);
                  setBody(n.body);
                }}
              >
                Update
              </Button>
            )}
          </Stack>
        </Card>
      ))}
    </Container>
  );
};

export default AdminNewsletter;
